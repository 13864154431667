import React from 'react'
import { Typography } from "@mui/material";

function HeroText() {
    return (
       <>
        <Typography component="h1" sx={{fontSize: "42px", fontWeight: "700"}}>
        Elysee - Free Online Games
        </Typography>
        <Typography component="p" sx={{ fontSize: "16px", mb: "4em", maxWidth: "850px"}}>
        Préparez-vous à vivre une expérience de jeu exceptionnelle avec Elysee no money casino games, votre espace dédié à des jeux de casino gratuits, où le divertissement est roi et les risques financiers sont absents.
        </Typography>
       </>
    )
}

export default HeroText
