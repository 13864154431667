import React from 'react'
import { Box, Typography } from "@mui/material";

function ContactText() {
  return (
    <Box
      sx={{
        maxWidth: "920px",
        width: "100%",
        background: "#fff",
        m: "20px auto 20px",
        p: "20px 35px",
        borderRadius: "15px",
        textAlign: "left",
        boxSizing: 'border-box'
      }}
    >
      <Typography
        component="h1"
        sx={{
          fontSize: "24px",
          fontWeight: 700,
          p: "10px 0",
          textAlign: "left",
          color: "#111"
        }}
      >
        Entrer en contact
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", mb: '90px', color: "#111" }}>
      Vous avez une question sur Elysee Casino? Nous aimerions recevoir de vos nouvelles!
      <br/>
      Envoyez-nous un message et nous vous répondrons dès que possible:
      <br/><br/>
      <h3><a href="mailto:contact@elyseecasino.com">contact@elyseecasino.com</a></h3>
      <h3><a href="tel:+33155321148">+33 1 55 32 11 48</a></h3>
      </Typography>
      </Box>
  )
}

export default ContactText