import React from 'react'
import DescriptionText from '../components/Content/DescriptionText';
import GamesList from '../components/Games/GamesList';

function GameslistPage() {
  return (
    
    <div className="games-container">
      <h1>Tous les jeux</h1>
        <GamesList />
  </div>
  )
}

export default GameslistPage