import React from "react";
import img from "../../media/Vector.png";
import { Link } from "react-router-dom";

import favicon from "../../media/icon.png";
import HeaderNav from "./HeaderNav";

function Header() {
  return (
    <header className="header">
      <HeaderNav />
    </header>
  );
}

export default Header;
