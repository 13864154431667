import React from "react";
import { Box, Typography } from "@mui/material";

function PolicyText() {
  return (
    <Box
      sx={{
        maxWidth: "920px",
        width: "100%",
        background: "#fff",
        m: "20px auto 20px",
        p: "20px 35px",
        borderRadius: "15px",
        textAlign: "left", 
        boxSizing: "border-box",
        fontSize: '12px'
      }}
    >
      <Typography
        component="h1"
        sx={{
          fontSize: "24px",
          fontWeight: 700,
          p: "10px 0",
          textAlign: "left", color: "#111"
        }}
      >
        Politique de confidentialité de Elysee Casino
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Dernière mise à jour: 1er décembre 2023
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Cette politique de confidentialité décrit nos politiques et procédures sur le
        Collection, utilisation et divulgation de vos informations lorsque vous utilisez le
        Service et vous parle de vos droits à la vie privée et de la façon dont la loi protège
        Toi.
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Nous utilisons vos données personnelles pour fournir et améliorer le service.En utilisant
        Le service, vous acceptez la collecte et l'utilisation d'informations dans
        conformément à cette politique de confidentialité.
      </Typography>
      <Typography
        component="h2"
        sx={{
          fontSize: "16px",
          fontWeight: 700,
          p: "10px 0",
          textAlign: "left", color: "#111"
        }}
      >
        Interprétation et définitions
      </Typography>
      <Typography
        component="h2"
        sx={{
          fontSize: "15px",
          fontWeight: 700,
          p: "10px 0",
          textAlign: "left", color: "#111"
        }}
      >
        Interprétation
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Les mots dont la lettre initiale est capitalisée a des significations
        défini dans les conditions suivantes.Les définitions suivantes doivent
        avoir la même signification, qu'ils apparaissent en singulier ou
        au pluriel.
      </Typography>
      <Typography
        component="h2"
        sx={{
          fontSize: "15px",
          fontWeight: 700,
          p: "10px 0",
          textAlign: "left", color: "#111"
        }}
      >
        Définitions
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Aux fins de la présente politique de confidentialité:
      </Typography>
      <ul>
        <li>
          <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
            <strong> compte </strong> signifie un compte unique créé pour vous
            accéder à notre service ou aux parties de notre service.
          </Typography>
        </li>
        <li>
          <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
            <strong> affilié </strong> signifie une entité qui contrôle,
            contrôlé par ou est sous contrôle commun avec une partie, où
            & quot; contrôle & quot;signifie la propriété de 50% ou plus des actions,
            intérêts en actions ou autres titres autorisés à voter pour l'élection de
            directeurs ou autres autorités de gestion.
          </Typography>
        </li>
        <li>
          <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
            <strong> société </strong> (appelée soit "le"
            Société "," nous ",", États-Unis "us;ou "notre"dans
            Cet accord) fait référence à Elysee Casino.
          </Typography>
        </li>
        <li>
          <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
            <strong> cookies </strong> sont de petits fichiers qui sont placés sur votre
            ordinateur, appareil mobile ou tout autre appareil par un site Web, contenant
            les détails de votre historique de navigation sur ce site Web parmi ses nombreux
            les usages.
          </Typography>
        </li>
        <li>
          <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
            <strong> pays </strong> fait référence à: Territoire de la capitale australienne,
            France
          </Typography>
        </li>
        <li>
          <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
            <strong> périphérique </strong> signifie tout appareil qui peut accéder au service
            comme un ordinateur, un téléphone portable ou une tablette numérique.
          </Typography>
        </li>
        <li>
          <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
            <strong> Les données personnelles </strong> sont toutes les informations qui se rapportent à un
            individu identifié ou identifiable.
          </Typography>
        </li>
        <li>
          <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
            <strong> Service </strong> fait référence au site Web.
          </Typography>
        </li>
        <li>
          <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
            <strong> fournisseur de services </strong> signifie toute personne naturelle ou légale
            L'OMS traite les données au nom de l'entreprise.Ça fait référence à
            des entreprises ou des particuliers tiers employés par l'entreprise
            faciliter le service, pour fournir le service au nom du
            Entreprise, pour effectuer des services liés au service ou pour aider
            Entreprise en analysant comment le service est utilisé.
          </Typography>
        </li>
        <li>
          <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
            <strong> Les données d'utilisation </strong> se réfèrent automatiquement aux données collectées,
            soit généré par l'utilisation du service ou du service
            infrastructure elle-même (par exemple, la durée d'une visite de page).
          </Typography>
        </li>
        <li>
          <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
            <strong> Le site Web </strong> fait référence à Elysee Casino, accessible à partir de.
            <a
              href="https://elyseecasino.com"
            >
              https://elyseecasino.com
            </a>
          </Typography>
        </li>
        <li>
          <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
            <strong> vous </strong> désigne l'accès individuel ou l'utilisation
            Service, ou la société, ou toute autre entité juridique au nom desquelles
            Cette personne accéde ou utilise le service, le cas échéant.
          </Typography>
        </li>
      </ul>
      <Typography
        component="h2"
        sx={{
          fontSize: "16px",
          fontWeight: 700,
          p: "10px 0",
          textAlign: "left", color: "#111"
        }}
      >
       Collecte et utilisation de vos données personnelles
      </Typography>
      <Typography
        component="h2"
        sx={{
          fontSize: "15px",
          fontWeight: 700,
          p: "10px 0",
          textAlign: "left", color: "#111"
        }}
      >
        Types de données collectées
      </Typography>
      <Typography
        component="h2"
        sx={{
          fontSize: "14px",
          fontWeight: 700,
          p: "10px 0",
          textAlign: "left", color: "#111"
        }}
      >
        Données personnelles
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Tout en utilisant notre service, nous pouvons vous demander de nous fournir certains
        informations personnellement identifiables qui peuvent être utilisées pour contacter ou
        vous identifier.Les informations personnellement identifiables peuvent inclure, mais est
        pas limité à:
      </Typography>
      <ul>
        <li>Des données d'utilisation</li>
      </ul>
      <Typography
        component="h2"
        sx={{
          fontSize: "14px",
          fontWeight: 700,
          p: "10px 0",
          textAlign: "left", color: "#111"
        }}
      >
        Des données d'utilisation
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Les données d'utilisation sont collectées automatiquement lors de l'utilisation du service.
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Les données d'utilisation peuvent inclure des informations telles que Internet de votre appareil
        Adresse du protocole (par exemple l'adresse IP), type de navigateur, version du navigateur, la
        pages de notre service que vous visitez, l'heure et la date de votre visite,
        le temps passé sur ces pages, les identificateurs d'appareils uniques et autres
        Données de diagnostic.
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Lorsque vous accédez au service par ou via un appareil mobile, nous pouvons
        Recueillir automatiquement certaines informations, y compris, mais sans limiter
        à, le type d'appareil mobile que vous utilisez, votre identifiant unique de l'appareil mobile, le
        Adresse IP de votre appareil mobile, votre système d'exploitation mobile, le type
        du navigateur Internet mobile que vous utilisez, des identificateurs d'appareils uniques et d'autres
        Données de diagnostic.
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Nous pouvons également collecter des informations que votre navigateur envoie chaque fois que vous
        Visitez notre service ou lorsque vous accédez au service par ou via un mobile
        appareil.
      </Typography>
      <Typography
        component="h2"
        sx={{
          fontSize: "14px",
          fontWeight: 700,
          p: "10px 0",
          textAlign: "left", color: "#111"
        }}
      >
        Technologies de suivi et cookies
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Nous utilisons des cookies et des technologies de suivi similaires pour suivre l'activité
        sur notre service et stocker certaines informations.Technologies de suivi utilisées
        sont des balises, des balises et des scripts pour collecter et suivre les informations et à
        améliorer et analyser notre service.Les technologies que nous utilisons peuvent inclure:
      </Typography>
      <ul>
        <li>
          <strong> cookies ou cookies de navigateur. </strong> Un cookie est un petit fichier
          placé sur votre appareil.Vous pouvez demander à votre navigateur de refuser tout
          Cookies ou indiquer quand un cookie est envoyé.Cependant, si tu fais
          N'acceptez pas les cookies, vous ne pourrez peut-être pas utiliser certaines parties de notre
          Service.Sauf si vous avez ajusté le paramètre de votre navigateur pour qu'il
          Refuser les cookies, notre service peut utiliser des cookies.
        </li>
        <li>
          <strong> balises Web. </strong> certaines sections de notre service et notre
          Les e-mails peuvent contenir de petits fichiers électroniques appelés balises Web (également
          appelés gifs clairs, balises de pixels et gifs à pixels) que
          permettre à l'entreprise, par exemple, de compter les utilisateurs qui ont visité ces
          pages ou ouvert un e-mail et pour d'autres statistiques de site Web connexes (pour
          exemple, enregistrer la popularité d'une certaine section et vérifier
          Intégrité du système et du serveur).
        </li>
      </ul>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Les cookies peuvent être "persistants"ou "session"Biscuits.
        Les cookies persistants restent sur votre ordinateur personnel ou votre appareil mobile
        Lorsque vous sortez hors ligne, tandis que les cookies de session sont supprimés dès que vous
        Fermez votre navigateur Web.
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Nous utilisons à la fois des cookies de session et des cookies persistants aux fins définies
        ci-dessous:
      </Typography>
      <ul>
        <li>
          <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
            <strong>Cookies nécessaires / essentiels</strong>
          </Typography>
          <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
            Type: cookies de session
          </Typography>
          <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
            Administré par: nous
          </Typography>
          <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
           Objectif: ces cookies sont essentiels pour vous fournir des services
            Disponible sur le site Web et vous permettre d'utiliser une partie de son
            caractéristiques.Ils aident à authentifier les utilisateurs et à prévenir une utilisation frauduleuse
            des comptes d'utilisateurs.Sans ces cookies, les services que vous avez
            demandée ne peut pas être fournie, et nous utilisons uniquement ces cookies pour
            vous fournir ces services.
          </Typography>
        </li>
        <li>
          <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
            <strong> Cookies Politique / Avis Accèdent les cookies </strong>
          </Typography>
          <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
            Type: cookies persistants
          </Typography>
          <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
            Administré par: nous
          </Typography>
          <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
            Objectif: ces cookies identifient si les utilisateurs ont accepté l'utilisation de
            Cookies sur le site Web.
          </Typography>
        </li>
        <li>
          <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
            <strong>Cookies de fonctionnalité</strong>
          </Typography>
          <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
            Type: cookies persistants
          </Typography>
          <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
            Administré par: nous
          </Typography>
          <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
            Objectif: ces cookies nous permettent de nous souvenir des choix que vous faites quand
            Vous utilisez le site Web, comme vous souvenir de vos détails de connexion ou
            Préférence de langue.Le but de ces cookies est de vous fournir
            avec une expérience plus personnelle et pour éviter que vous ayez à rentrer
            Vos préférences à chaque fois que vous utilisez le site Web.
          </Typography>
        </li>
      </ul>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Pour plus d'informations sur les cookies que nous utilisons et vos choix concernant
        cookies, veuillez visiter notre politique de cookies ou la section cookies de notre
        Politique de confidentialité.
      </Typography>
      <Typography
        component="h2"
        sx={{
          fontSize: "15px",
          fontWeight: 700,
          p: "10px 0",
          textAlign: "left", color: "#111"
        }}
      >
       Utilisation de vos données personnelles
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        L'entreprise peut utiliser des données personnelles aux fins suivantes:
      </Typography>
      <ul>
        <li>
            <strong> pour fournir et maintenir notre service </strong>, y compris à
            Surveillez l'utilisation de notre service.
        </li>
        <li>
            <strong> pour gérer votre compte: </strong> pour gérer votre enregistrement
            en tant qu'utilisateur du service.Les données personnelles que vous fournissez peuvent vous donner
            accès à différentes fonctionnalités du service qui sont
            à votre disposition en tant qu'utilisateur enregistré.
        </li>
        <li>
            <strong> pour la performance d'un contrat: </strong> le développement,
            conformité et entreprendre le contrat d'achat pour le
            produits, articles ou services que vous avez achetés ou de tout autre
            Contractez avec nous via le service.
        </li>
        <li>
            <strong> pour vous contacter: </strong> pour vous contacter par e-mail, téléphone
            appels, SMS ou autres formes équivalentes de communication électronique,
            comme les notifications push d'une application mobile concernant les mises à jour
            ou communications informatives liées aux fonctionnalités,
            produits ou services contractuels, y compris les mises à jour de sécurité,
            si nécessaire ou raisonnable pour leur mise en œuvre.
        </li>
        <li>
            <strong> pour vous fournir </strong> des nouvelles, des offres spéciales et
            Informations générales sur d'autres biens, services et événements que nous
            offre similaire à celle que vous avez déjà achetée ou
            Enquête sur sauf si vous avez choisi de ne pas recevoir de tel
            information.
        </li>
        <li>
            <strong> pour gérer vos demandes: </strong> pour assister et gérer votre
            Demandes à nous.
        </li>
        <li>
            <strong> pour les transferts commerciaux: </strong> Nous pouvons utiliser vos informations
            pour évaluer ou mener une fusion, une désinvestissement, une restructuration,
            réorganisation, dissolution ou autre vente ou transfert de certains ou
            Tous nos actifs, que ce soit en activité ou en tant que partie de
            faillite, liquidation ou procédure similaire, dans laquelle
            Les données détenues par nous sur nos utilisateurs de services font partie des actifs
            transféré.
        </li>
        <li>
            <strong> à d'autres fins </strong>: nous pouvons utiliser vos informations pour
            D'autres objectifs, tels que l'analyse des données, l'identification des tendances d'utilisation,
            déterminer l'efficacité de nos campagnes promotionnelles et
            Évaluez et améliorez nos services, nos produits, nos services, notre marketing et
            ton expérience.
        </li>
      </ul>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Nous pouvons partager vos informations personnelles dans les situations suivantes:
      </Typography>
      <ul>
        <li>
          <strong> avec les fournisseurs de services: </strong> Nous pouvons partager votre personnel
          des informations avec les fournisseurs de services pour surveiller et analyser l'utilisation de
          Notre service, pour vous contacter.
        </li>
        <li>
          <strong> pour les transferts commerciaux: </strong> Nous pouvons partager ou transférer votre
          informations personnelles en lien avec ou pendant les négociations de,
          Toute fusion, vente d'actifs de l'entreprise, financement ou acquisition de tous
          ou une partie de notre entreprise à une autre entreprise.
        </li>
        <li>
          <strong> avec des affiliés: </strong> nous pouvons partager vos informations avec
          Nos affiliés, auquel cas nous aurons besoin de ces affiliés
          honorer cette politique de confidentialité.Les affiliés incluent notre société mère et
          Toutes les autres filiales, partenaires de coentreprise ou autres sociétés
          Nous contrôlons ou qui sont sous contrôle commun avec nous.
        </li>
        <li>
          <strong> avec des partenaires commerciaux: </strong> Nous pouvons partager vos informations
          avec nos partenaires commerciaux pour vous offrir certains produits, services ou
          promotions.
        </li>
        <li>
          <strong> avec les autres utilisateurs: </strong> lorsque vous partagez des informations personnelles
          ou interagir autrement dans les domaines publics avec d'autres utilisateurs, tels
          Les informations peuvent être consultées par tous les utilisateurs et peuvent être distribuées publiquement
          dehors.
        </li>
        <li>
          <strong> avec votre consentement </strong>: Nous pouvons divulguer votre personnel
          informations à toute autre fin avec votre consentement.
        </li>
      </ul>
      <Typography
        component="h2"
        sx={{
          fontSize: "15px",
          fontWeight: 700,
          p: "10px 0",
          textAlign: "left", color: "#111"
        }}
      >
        Rétention de vos données personnelles
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        L'entreprise conservera vos données personnelles uniquement aussi longtemps que
        nécessaire aux fins énoncées dans la présente politique de confidentialité.Nous allons
        conserver et utiliser vos données personnelles dans la mesure nécessaire pour se conformer
        nos obligations légales (par exemple, si nous sommes tenus de conserver votre
        données pour se conformer aux lois applicables), résoudre les litiges et appliquer notre
        accords et politiques juridiques.
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        La société conservera également des données d'utilisation à des fins d'analyse interne.
        Les données d'utilisation sont généralement conservées pendant une période plus courte, sauf
        Lorsque ces données sont utilisées pour renforcer la sécurité ou pour améliorer
        fonctionnalité de notre service, ou nous sommes légalement obligés de conserver cela
        données pendant des périodes de temps plus longues.
      </Typography>
      <Typography
        component="h2"
        sx={{
          fontSize: "15px",
          fontWeight: 700,
          p: "10px 0",
          textAlign: "left", color: "#111"
        }}
      >
        Transfert de vos données personnelles
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Vos informations, y compris les données personnelles, sont traitées chez la société
        bureaux d'exploitation et dans tous autres endroits où les parties impliquaient
        Le traitement est situé.Cela signifie que ces informations peuvent être
        Transféré à - et maintenu sur - des ordinateurs situés à l'extérieur de votre
        État, province, pays ou autre juridiction gouvernementale où
        Les lois sur la protection des données peuvent différer de celles de votre juridiction.
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Votre consentement à cette politique de confidentialité suivie de votre soumission
        Les informations représentent votre accord à ce transfert.
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        L'entreprise prendra toutes les mesures raisonnablement nécessaires pour vous assurer que votre
        Les données sont traitées en toute sécurité et conformément à la présente politique de confidentialité et
        Aucun transfert de vos données personnelles n'aura lieu à une organisation ou
        un pays à moins qu'il y ait des contrôles adéquats en place, notamment le
        Sécurité de vos données et autres informations personnelles.
      </Typography>
      <Typography
        component="h2"
        sx={{
          fontSize: "15px",
          fontWeight: 700,
          p: "10px 0",
          textAlign: "left", color: "#111"
        }}
      >
        Supprimez vos données personnelles
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Vous avez le droit de supprimer ou de demander que nous aidons à supprimer le
        Données personnelles que nous avons collectées à votre sujet.
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Notre service peut vous donner la possibilité de supprimer certaines informations sur
        Vous depuis le service.
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Vous pouvez mettre à jour, modifier ou supprimer vos informations à tout moment en signant
        dans votre compte, si vous en avez un et visitez les paramètres du compte
        Section qui vous permet de gérer vos informations personnelles.Vous pouvez
        Contactez-nous également pour demander l'accès, corriger ou supprimer tout personnel
        informations que vous nous avez fournies.
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Veuillez noter, cependant, que nous pourrions avoir besoin de conserver certaines informations
        Lorsque nous avons une obligation légale ou une base légale pour le faire.
      </Typography>
      <Typography
        component="h2"
        sx={{
          fontSize: "15px",
          fontWeight: 700,
          p: "10px 0",
          textAlign: "left", color: "#111"
        }}
      >
        Divulgation de vos données personnelles
      </Typography>
      <Typography
        component="h2"
        sx={{
          fontSize: "14px",
          fontWeight: 700,
          p: "10px 0",
          textAlign: "left", color: "#111"
        }}
      >
        Transactions commerciales
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Si la société est impliquée dans une fusion, une acquisition ou une vente d'actifs, votre
        Les données personnelles peuvent être transférées.Nous fournirons un avis avant votre
        Les données personnelles sont transférées et deviennent soumises à une confidentialité différente
        Politique.
      </Typography>
      <Typography
        component="h2"
        sx={{
          fontSize: "14px",
          fontWeight: 700,
          p: "10px 0",
          textAlign: "left", color: "#111"
        }}
      >
        Forces de l'ordre
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Dans certaines circonstances, l'entreprise peut être tenue de divulguer
        Vos données personnelles si nécessaire pour le faire par la loi ou en réponse à
        Demandes des autorités publiques (par exemple un tribunal ou une agence gouvernementale).
      </Typography>
      <Typography
        component="h2"
        sx={{
          fontSize: "14px",
          fontWeight: 700,
          p: "10px 0",
          textAlign: "left", color: "#111"
        }}
      >
        Autres exigences légales
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        L'entreprise peut divulguer vos données personnelles dans la croyance de bonne foi
        qu'une telle action est nécessaire pour:
      </Typography>
      <ul>
        <li> Conforme à une obligation légale </li>
        <li> Protéger et défendre les droits ou les biens de la Société </li>
        <li>
          Empêcher ou étudier les actes répréhensibles possibles en relation avec le
          Service
        </li>
        <li>
          Protégez la sécurité personnelle des utilisateurs du service ou du public
        </li>
        <li> Protéger contre la responsabilité légale </li>
      </ul>
      <Typography
        component="h2"
        sx={{
          fontSize: "15px",
          fontWeight: 700,
          p: "10px 0",
          textAlign: "left", color: "#111"
        }}
      >
        Sécurité de vos données personnelles
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        La sécurité de vos données personnelles est importante pour nous, mais n'oubliez pas que
        Aucune méthode de transmission sur Internet, ni méthode d'électronique
        Le stockage est à 100% sécurisé.Tandis que nous nous efforçons d'utiliser commercialement acceptable
        signifie protéger vos données personnelles, nous ne pouvons garantir son absolu
        sécurité.
      </Typography>
      <Typography
        component="h2"
        sx={{
          fontSize: "16px",
          fontWeight: 700,
          p: "10px 0",
          textAlign: "left", color: "#111"
        }}
      >
        Intimité des enfants
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Notre service ne traite de personne de moins de 13 ans. Nous ne
        Collectez sciemment des informations personnellement identifiables de toute personne sous
        l'âge de 13 ans. Si vous êtes parent ou tuteur et que vous savez que
        Votre enfant nous a fourni des données personnelles, veuillez nous contacter.Si nous
        prendre conscience que nous avons collecté des données personnelles à quiconque
        Âge de 13 ans sans vérification du consentement parental, nous prenons des mesures pour
        Supprimez ces informations de nos serveurs.
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Si nous devons compter sur le consentement comme base juridique pour traiter votre
        les informations et votre pays nécessite le consentement d'un parent, nous pouvons
        nécessitent le consentement de vos parents avant de collecter et de l'utiliser
        information.
      </Typography>
      <Typography
        component="h2"
        sx={{
          fontSize: "16px",
          fontWeight: 700,
          p: "10px 0",
          textAlign: "left", color: "#111"
        }}
      >
        Liens vers d'autres sites Web
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Notre service peut contenir des liens vers d'autres sites Web qui ne sont pas exploités par
        Nous.Si vous cliquez sur un lien tiers, vous serez dirigé vers cela
        Site de tiers.Nous vous conseillons fortement de revoir la politique de confidentialité
        de chaque site que vous visitez.
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Nous n'avons aucun contrôle sur et assumez aucune responsabilité pour le contenu,
        Politiques ou pratiques de confidentialité de tous les sites ou services tiers.
      </Typography>
      <Typography
        component="h2"
        sx={{
          fontSize: "16px",
          fontWeight: 700,
          p: "10px 0",
          textAlign: "left", color: "#111"
        }}
      >
        Modifications de cette politique de confidentialité
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Nous pouvons mettre à jour notre politique de confidentialité de temps à autre.Nous vous informerons
        de tout changement en publiant la nouvelle politique de confidentialité sur cette page.
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Nous vous informerons par e-mail et / ou un avis de premier plan sur notre service,
        Avant que le changement ne devienne efficace et met à jour le "dernier
        Mise à jour & quot;Date en haut de cette politique de confidentialité.
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Il est conseillé de revoir périodiquement cette politique de confidentialité pour tout
        changements.Les modifications de cette politique de confidentialité sont efficaces lorsqu'ils sont
        Publié sur cette page.
      </Typography>
      <Typography
        component="h2"
        sx={{
          fontSize: "16px",
          fontWeight: 700,
          p: "10px 0",
          textAlign: "left", color: "#111"
        }}
      >
        Contactez-nous
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Si vous avez des questions sur cette politique de confidentialité, vous pouvez nous contacter via:
      </Typography>
      <ul>
        <li>
          <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
          
          24 Rue Paul Bert 75011 Paris
          </Typography>
        </li>
        <li>
          <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
            Par email: contact@elyseecasino.com
          </Typography>
        </li>
      </ul>
    </Box>
  );
}

export default PolicyText;
