import React from 'react'
import DescriptionText from '../components/Content/DescriptionText';
import GamesList from '../components/Games/GamesList';
import HeroText from '../components/Content/HeroText';

function HomePage() {
  return (
    
    <div className="games-container">
        <HeroText />
        
        <h2>Tous les jeux</h2>
        <GamesList />
        <DescriptionText />
  </div>
  )
}

export default HomePage