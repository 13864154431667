import { Box, Typography } from "@mui/material";
import React from "react";

function DescriptionText() {
  return (
    <Box
      sx={{
        maxWidth: "920px",
        width: "100%",
        background: "#fff",
        m: "80px auto 20px",
        p: "30px 35px 60px",
        borderRadius: "15px",
        textAlign: "left",
        boxSizing: 'border-box',
        color: "#111"
      }}
    >
      <Typography
        component="h2"
        sx={{
          fontSize: "24px",
          fontWeight: 700,
          p: "10px 0",
          textAlign: "left",
          color: "#111"
        }}
      >
        Pourquoi Choisir Elysee Casino ?
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Chez Elysee Casino, nous avons sélectionné avec soin une collection impressionnante de jeux de casino qui répondent à tous les goûts. Que vous soyez un joueur occasionnel en quête de divertissement ou un amateur de casino chevronné, notre plateforme a été conçue pour répondre à vos attentes.
      </Typography>
      <Typography
        component="h2"
        sx={{
          fontSize: "16px",
          fontWeight: 700,
          p: "10px 0",
          textAlign: "left",
          color: "#111"
        }}
      >
        Des Jeux de Casino Gratuits en Abondance
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Explorez notre vaste bibliothèque de jeux de casino gratuits qui vous offre une variété de choix. Des machines à sous aux jeux de table classiques en passant par le vidéo poker, notre sélection couvre l'ensemble du spectre du jeu de casino. Plongez dans l'excitation du casino sans jamais toucher à votre portefeuille.
      </Typography>
      <Typography
        component="h2"
        sx={{
          fontSize: "16px",
          fontWeight: 700,
          p: "10px 0",
          textAlign: "left",
          color: "#111"
        }}
      >
        Sécurité et Jeu Équitable Garantis
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Votre sécurité et une expérience de jeu équitable sont nos priorités absolues. Soyez assuré que tous nos jeux sont conçus pour être justes et équitables, avec une protection de vos données selon les normes de l'industrie. Profitez de votre expérience de jeu en toute tranquillité.
      </Typography>
      <Typography
        component="h2"
        sx={{
          fontSize: "16px",
          fontWeight: 700,
          p: "10px 0",
          textAlign: "left",
          color: "#111"
        }}
      >
        Rejoignez Notre Communauté
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Elysee Casino est bien plus qu'une simple plateforme de jeu ; c'est une communauté florissante de joueurs venus du monde entier. Connectez-vous avec d'autres passionnés, partagez vos expériences et faites de nouveaux amis grâce à nos forums et à nos salles de discussion.
      </Typography>
      <Typography
        component="h2"
        sx={{
          fontSize: "16px",
          fontWeight: 700,
          p: "10px 0",
          textAlign: "left",
          color: "#111"
        }}
      >
        Commencez Votre Aventure Dès Aujourd'hui
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Que vous soyez là pour un jeu rapide de détente ou pour une immersion complète dans le monde du casino, Elysee Casino est votre passeport pour un divertissement sans fin. Rejoignez-nous dès maintenant et découvrez un monde d'excitation à portée de clic.
        <br /><br />
        N'oubliez pas qu'avec Elysee Casino, l'accent est mis sur le plaisir du jeu, sans jamais mettre en jeu de l'argent réel. Bienvenue dans le monde des jeux de casino gratuits, où chaque instant est une opportunité de gagner sans risque.
        <br /><br />
        Êtes-vous prêt à jouer, à vous divertir et à remporter des victoires ? Commencez votre aventure avec nous dès aujourd'hui !
      </Typography>
    </Box>
  );
}

export default DescriptionText;
