import React from "react";
import { Link } from "react-router-dom";
import games from "./games";

function GamesList() {
  return (
    <div className="games">
      {games.map((game, index) => (
        <Link key={index} to={game?.url} className="game" relative="path">
          <img src={game.image} alt={game.title} className="game__image" />
        </Link>
      ))}
    </div>
  );
}

export default GamesList;
