import React from 'react'
import { Box, Typography } from "@mui/material";

function GamePage({game}) {
    const { title, iframe } = game;

  return (
    <Box
      sx={{
        maxWidth: "920px",
        width: "100%",
        background: "#fff",
        m: "20px auto",
        p: "10px",
        borderRadius: "15px",
        textAlign: "left",
        boxSizing: 'border-box'
      }}
    >
      <Typography
        component="h1"
        sx={{
          fontSize: "24px",
          fontWeight: 700,
          p: "10px 0",
          textAlign: "left",
          textTransform: 'capitalize',
          color: "#111"
        }}
      >
        {title}
      </Typography>
      <iframe title={title} frameborder="0" allow="autoplay" allowfullscreen="" seamless="" scrolling="no" src={iframe}></iframe>
      </Box>
  )
}

export default GamePage