
import img4 from '../../media/IiwidG9wRG9tYWluIjoiZ2FtZWRpc3RyaWJ1dGlv.jpg'
import img5 from '../../media/qnftmgqxllc2l19j6i9btljfsq6l263bdo23ahdy.jpg'
import img6 from '../../media/8lzd91nuutwmhm7ggrtvp7hc1oq4or18nwnewcbv.jpg'
import img7 from '../../media/iukn5nvr31hwd85dpze5csp9axbekdz5548osxir.jpg'
import img8 from '../../media/r2571itnc02g03cay7pp8em6j5mygy62f3j7fjgo.jpg'
import img9 from '../../media/0aghn40km8dqwoa7769z49k32d683k4pob3ib4el.jpg'
import img10 from '../../media/9900jpyhvhxaia84geujqus0ccrszf2mxko170mi.jpg'
import img11 from '../../media/jhlb6bwe4fikevf1qnftmgqxllc2l19j6i9btljf.jpg'
import img12 from '../../media/sq6l263bdo23ahdy8lzd91nuutwmhm7ggrtvp7hc.jpg'
import img14 from '../../media/axbekdz5548osxirr2571itnc02g03cay7pp8em6.jpg'
import img19 from '../../media/utwmhm7ggrtvp7hc1oq4or18nwnewcbviukn5nvr.jpg'
import img21 from '../../media/c02g03cay7pp8em6j5mygy62f3j7fjgo0aghn40k.jpg'
import img22 from '../../media/m8dqwoa7769z49k32d683k4pob3ib4el9900jpyh.jpg'
import img23 from '../../media/vhxaia84geujqus0ccrszf2mxko170mijhlb6bwe.jpg'
import img24 from '../../media/4fikevf1qnftmgqxllc2l19j6i9btljfsq6l263b.jpg'

const games = [
  
  {
    id: "IiwidG9wRG9tYWluIjoiZ2FtZWRpc3RyaWJ1dGlv",
    title: "gargantoonz",
    category: ['slot'],
    image: img4,
    url: 'gargantoonz',
    iframe: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=gargantoonz&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  {
    id: "qnftmgqxllc2l19j6i9btljfsq6l263bdo23ahdy",
    title: "Piggy Blitz",
    category: ['slot'],
    image: img5,
    url: 'piggy-blitz',
    iframe: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=piggyblitz&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  {
    id: "8lzd91nuutwmhm7ggrtvp7hc1oq4or18nwnewcbv",
    title: "Sweet Alchemy 100",
    category: ['slot'],
    image: img6,
    url: 'sweet-alchemy-100',
    iframe: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=sweetalchemy100&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  {
    id: "iukn5nvr31hwd85dpze5csp9axbekdz5548osxir",
    title: "Boat Bonanza Colossal Catch",
    category: ['slot'],
    image: img7,
    url: 'boat-bonanza-colossal-catch',
    iframe: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=boatbonanza&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  {
    id: "r2571itnc02g03cay7pp8em6j5mygy62f3j7fjgo",
    title: "Fox Mayhem",
    category: ['slot'],
    image: img8,
    url: 'fox-mayhem',
    iframe: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=foxmayhem&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  {
    id: "0aghn40km8dqwoa7769z49k32d683k4pob3ib4el",
    title: "Mega Don",
    category: ['slot'],
    image: img9,
    url: 'mega-don',
    iframe: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=megadon&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  {
    id: "9900jpyhvhxaia84geujqus0ccrszf2mxko170mi",
    title: "craps",
    category: [],
    image: img10,
    url: 'craps',
    iframe: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=craps&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  {
    id: "jhlb6bwe4fikevf1qnftmgqxllc2l19j6i9btljf",
    title: "mini baccarat",
    category: [],
    image: img11,
    url: 'mini-baccarat',
    iframe: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=minibaccarat&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  {
    id: "sq6l263bdo23ahdy8lzd91nuutwmhm7ggrtvp7hc",
    title: "BlackJack MH",
    category: [],
    image: img12,
    url: 'blackjack-mh',
    iframe: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=blackjackmh&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  {
    id: "axbekdz5548osxirr2571itnc02g03cay7pp8em6",
    title: "Super Wheel",
    category: [],
    image: img14,
    url: 'super-wheel',
    iframe: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=superwheel&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  {
    id: "utwmhm7ggrtvp7hc1oq4or18nwnewcbviukn5nvr",
    title: "Legacy of Dynasties",
    category: ['slot'],
    image: img19,
    url: 'legacy-of-dynasties',
    iframe: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=legacyofdynasties&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  {
    id: "c02g03cay7pp8em6j5mygy62f3j7fjgo0aghn40k",
    title: "Ruff Heist",
    category: ['slot'],
    image: img21,
    url: 'ruff-heist',
    iframe: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=ruffheist&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  {
    id: "m8dqwoa7769z49k32d683k4pob3ib4el9900jpyh",
    title: "Rascal Riches",
    category: ['slot'],
    image: img22,
    url: 'rascal-riches',
    iframe: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=rascalriches&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  {
    id: "vhxaia84geujqus0ccrszf2mxko170mijhlb6bwe",
    title: "ternion",
    category: ['slot'],
    image: img23,
    url: 'ternion',
    iframe: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=ternion&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  {
    id: "4fikevf1qnftmgqxllc2l19j6i9btljfsq6l263b",
    title: "slashimi",
    category: ['slot'],
    image: img24,
    url: 'slashimi',
    iframe: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=slashimi&lang=en_GB&practice=1&channel=desktop&demo=2",
  }
];

export default games.sort(() => 0.5 - Math.random());
