import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import PolicyPage from "./pages/PolicyPage";
import TermsPage from "./pages/TermsPage";
import ContactPage from "./pages/ContactPage";
import games from "./components/Games/games";
import GamePage from "./pages/GamePage";
import ScrollTop from "./ScrollTop";
import GameslistPage from "./pages/GameslistPage";
import AgeModal from "./components/Modal";

function App() {
  return (
    <BrowserRouter>
      <div className="wrapper">
        <Header />
        <ScrollTop />
        <main className="main">
          <Routes>
              <Route path="/">
              <Route index element={<HomePage />} />
              <Route path="policy" element={<PolicyPage />} />
              <Route path="terms" element={<TermsPage />} />
              <Route path="contact" element={<ContactPage />} />
              <Route path="games" element={<GameslistPage />} />
              {games.map((game) => {
                return(
                  <>
                    <Route path={game.url} element={<GamePage game={game} />} />
                    <Route path={"games/"+game.url} element={<GamePage game={game} />} />
                  </>
                )
                })}
            </Route>
          </Routes>
        </main>
        <AgeModal />
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
